import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Layout from 'layout/Layout';
import { OG_PAGE_TYPES } from 'common/Seo/constants';
import Faq from 'components/Faq';
import FaqOverview from 'components/vanishVictories/FaqOverview';
import VictoriesBanner from 'components/vanishVictories/VictoriesBanner';
import createBreadcrumbs from 'utils/createBreadcrumbs';

import { VanishVictoriesFAQsPageProps } from './models';

import './VanishVictoriesFAQsPage.scss';

const VanishVictoriesFAQsPage: FC<VanishVictoriesFAQsPageProps> = ({
  data: {
    vanishVictoriesFaqsPage: { seo, langProps, banner, faq, faqOverview, footerType },
    allSitePage: { nodes },
  },
  pageContext: {
    breadcrumb: { crumbs },
    areBreadcrumbsDisplayed,
    pagePathname,
  },
  location: { pathname },
}) => {
  const breadcrumbs = createBreadcrumbs(crumbs, areBreadcrumbsDisplayed, nodes, pathname);
  const schemaImageUrl =
    banner?.[0]?.backgroundImage?.imagePicker?.childImageSharp?.gatsbyImageData?.images?.fallback
      ?.src || banner?.[0]?.backgroundImage?.imagePicker?.url;

  return (
    <Layout
      className="vanish-victories-faqs-page"
      {...{
        seo,
        seoOGType: OG_PAGE_TYPES.WEBSITE,
        langProps,
        breadcrumbs,
        pagePathname,
        additionalSchema: 'WebPage',
        schemaImageUrl,
      }}
      footerType={footerType}
    >
      <VictoriesBanner data={banner?.[0]} />

      {faqOverview?.[0] ? <FaqOverview data={faqOverview[0]} /> : null}

      <div className="faqs">
        {faq.map((faqContainerItem) => (
          <div className="faq-container" key={faqContainerItem.secondaryNavigationAnchor}>
            <Faq {...faqContainerItem} />
          </div>
        ))}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query($link: String) {
    vanishVictoriesFaqsPage(link: { eq: $link }) {
      seo {
        ...SEOStructureFragment
      }
      langProps {
        urls {
          ...languageUrls
        }
        lang
      }
      pageName
      footerType {
        type
      }
      banner {
        ...VictoriesBannerFragment
      }
      faq {
        ...FaqFragment
      }
      faqOverview {
        ...FaqOverviewFragment
      }
    }
    allSitePage {
      ...FragmentAllPageSite
    }
  }
`;

export default VanishVictoriesFAQsPage;
