import React, { FC } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { graphql } from 'gatsby';

import { DangerouslySetInnerHtml } from 'layout';

import { FaqOverviewProps } from './models';

import './FaqOverview.scss';

const FaqOverview: FC<FaqOverviewProps> = ({ data: { text, title } }) => (
  <Row>
    <Col data-testid="faq-overview" lg={12} className="faq-overview">
      <DangerouslySetInnerHtml className="faq-overview__title" html={title} />
      <DangerouslySetInnerHtml className="faq-overview__text" html={text} />
    </Col>
  </Row>
);

export const query = graphql`
  fragment FaqOverviewFragment on FaqOverviewType {
    title
    text
  }
`;

export default FaqOverview;
